import React from "react";
import Footer from "src/components/Footer_pt";

class Page extends React.Component {
  render() {
    return (
      <div>
        
        <div id="fb-root" />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "(function(d, s, id) {\nvar js, fjs = d.getElementsByTagName(s)[0];\nif (d.getElementById(id)) return;\njs = d.createElement(s); js.id = id;\njs.src = \"//connect.facebook.net/pt_PT/sdk.js#xfbml=1&version=v2.6&appId=607297119325946\";\nfjs.parentNode.insertBefore(js, fjs);\n}(document, 'script', 'facebook-jssdk'));\n"
          }}
        />
        <div id="disclosureText">
        </div>
        <div id="page" className="pageProducts">
          <div id="limit">
            <header id="header">
              <div className="mobileHeaderContainer clearfix">
                <a href="/pt/" className="logo">
                  Piz Buin
                </a>
                <button
                  id="btnShowMenu"
                  className="collapse-btn collapse-short-btn right"
                  data-target=".collapse-menu"
                  data-toggle="collapse"
                  type="button"
                />
              </div>
              <nav id="menu" className="collapse-menu collapse-short-menu">
                <ul className="nav-header">
                  <li className="nav-path-selected first has-sub-nav">
                    <a className="es_header" href="/pt/nossos-produtos/" target="_self">
                      Produtos
                    </a>
                    <ul>
                      <li>
                        <a href="/pt/nossos-produtos/hydro-infusion/">
                          Hydro Infusion
                        </a>
                      </li>
                      {/* <li>
                        <a href="/pt/nossos-produtos/active-protect/">
                          Active &amp; Protect
                        </a>
                      </li> */}
                      <li>
                        <a href="/pt/nossos-produtos/moisturising/">
                          Moisturising
                        </a>
                      </li>
                      <li>
                        <a href="/pt/nossos-produtos/tan_protect/">
                          Tan &amp; Protect
                        </a>
                      </li>
                      {/* <li>
                        <a href="/pt/nossos-produtos/instantglow/">
                          Instant Glow
                        </a>
                      </li> */}
                      <li>
                        <a href="/pt/nossos-produtos/allergy/">Allergy</a>
                      </li>
                      <li>
                        <a href="/pt/nossos-produtos/aftersun/">After sun</a>
                      </li>
                      <li>
                        <a href="/pt/nossos-produtos/todos_os_Produtos/">
                          Todos os Produtos
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a className="es_header" href="/pt/a_nossa_historia/" target="_self">
                      A Nossa História
                    </a>
                  </li>
                  <li>
                    <a className="es_header" href="/pt/sobre_o_sol/" target="_self">
                      Aprenda Sobre o Sol
                    </a>
                  </li>
                  {/*<li>
                    <a className="es_header" href="/pt/Por-Onde-Andaste/" target="_self">
                      Por Onde Andaste 2019
                    </a>
                  </li>*/}
                </ul>
                <ul className="countryNav">
                  <li
                    className="collapsible has-sub-nav"
                    style={{
                      "list-style": "none"
                    }}
                  >
                    <a className="countries" href="#">
                      PAÍSES
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a href="/en/our-products/aftersun/">INT</a>
                      </li>
                      <li>
                        <a href="/uk/our-products/aftersun/">UK</a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/after-sun/">ES</a>
                      </li>
                      <li>
                        <a href="/pt/nossos-produtos/aftersun/">PT</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/aftersun/">IT</a>
                      </li>
                      <li>
                        <a href="/gr/our-products/aftersun/">GR</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div id="menuExtra" />
            </header>
            
            <section id="desktop-hero-wrapper" className="product-hero">
              <div id="bgImage" >
                <div className="areaInner">
                  <img
                    border="0"
                    className="ccm-image-block"
                    alt
                    src="/assets/images/2018-pizbuin_1680x405px_aftersun_pt.jpg"
                    data-mobile="/assets/images/2018-pizbuin_905x405px_aftersun_pt.jpg"
                  />
                </div>
              </div>
            </section>
            <section id="content">
              <div className="products-page innerContent clearfix">
                <div className="products-page__intro">
                  <div className="container">
                    <h1>
                      PIZ BUIN<sup>®</sup> AFTER SUN
                    </h1>
                    <div className="products-page__intro__description">
                      <h3>Pós-solar. 24h de hidratação</h3>
                      <p>
                        Depois de um dia passado ao sol, a sua pele precisa de
                        ser revitalizada e hidratada. Cuide da sua pele com os
                        nossos produtos pós-solares para restaurar o seu
                        equilíbrio de hidratação natural. Enriquecidos com um
                        complexo de hidratação intensa, os PIZ BUIN<sup>®</sup>{" "}
                        AFTER SUN ajudam a proteger a pele contra a desidratação
                        e a manter um bronzeado mais deslumbrante e duradouro.
                        Todos os produtos pós-solares da PIZ BUIN<sup>®</sup>{" "}
                        contêm vitamina E, que ajuda a proteger contra o
                        envelhecimento prematuro da pele associado à exposição
                        solar.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="products-page__list container clearfix">
                  <div className="products-page__product clearfix">
                    <a name="lo-o-hidratante-suavizante-refrescante" />
                    <div className="products-page__product__image">
                      <img src="/assets/images/5f883c359b9eeeed84183c6dab5c1c41_f20-pta
                      .jpg" />
                    </div>
                    <div className="products-page__product__details">
                      <h3>LOÇÃO HIDRATANTE SUAVIZANTE &amp; REFRESCANTE</h3>
                      <div className="products-page__product__details__benefits">
                        <h4>BENEFÍCIOS</h4>
                        <p>
                          Enriquecida com Aloé Vera, a Loção Hidratante
                          Suavizante &amp; Refrescante de PIZ BUIN<sup>®</sup>{" "}
                          AFTER SUN proporciona 24h de hidratação para recuperar
                          o equilíbrio natural da pele exposta ao sol e ajuda a
                          prevenir a descamação proporcionando um bronzeado mais
                          duradouro. A fórmula ultrafresca, não gordurosa e de
                          rápida absorção contém extratos de menta para um
                          efeito refrescante imediato e duradouro.
                        </p>
                        <div className="products-page__product__details__benefits__hidden">
                          <p>
                            O PIZ BUIN<sup>®</sup> AFTER SUN Loção Hidratante
                            Suavizante &amp; Refrescante é aprovado por
                            consumidores. Num estudo, perguntámos a consumidores
                            a sua opinião sobre o nosso After Sun Loção
                            Hidratante Suavizante &amp; Refrescante*.
                          </p>
                          <p>
                            <strong>
                              <span className="orange">100%</span>
                            </strong>{" "}
                            confirmam:
                          </p>
                          <ul>
                            <li>Pele refrescada, suavizada e hidratada</li>
                            <li>Pele visivelmente mais suave</li>
                          </ul>
                          <p>
                            Mais do que{" "}
                            <strong>
                              <span className="orange">90%</span>
                            </strong>{" "}
                            confirmam:
                          </p>
                          <ul>
                            <li>Efeito refrescante imediato</li>
                            <li>
                              Pele imediatamente aliviada e visivelmente mais
                              macia
                            </li>
                            <li>Pele imediatamente confortada</li>
                            <li>Equilíbrio de hidratação natural restaurado</li>
                          </ul>
                          <p>
                            Mais do que{" "}
                            <strong>
                              <span className="orange">80%</span>
                            </strong>{" "}
                            confirmam:
                          </p>
                          <ul>
                            <li>Textura de rápida absorção e não gordurosa</li>
                          </ul>
                          <p> </p>
                          <h2>disponível nos formatos</h2>
                          <p> 200 ml</p>
                          <p> </p>
                          <p className="footNote">
                            *Estudo de opinião, 31 indivíduos, 3 semanas
                          </p>
                        </div>
                        <button className="products-page__product__details__benefits__more">
                          Saiba Mais
                        </button>
                      </div>
                      <div className="products-page__product__details__ingredients">
                        <ul className="ingredientsList">
                          <li>
                            <a href="#" className="toggle">
                              INGREDIENTES E TECNOLOGIAS{" "}
                              <span className="toggle__plus">+</span>
                            </a>
                            <ul>
                              <li>
                                ALOÉ VERA
                                <div className="textBlock bottom">
                                  <p>
                                    O que é que torna o Aloé Vera único?
                                    <br />
                                    Aloé Vera é um hidratante natural rico, com
                                    poderosas propriedades suavizantes.
                                  </p>
                                  <div> </div>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/006e44dceb4947a77acdd3948503af8d_f37.jpg" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                GLICERINA
                                <div className="textBlock bottom">
                                  <p>
                                    A Glicerina atrai e retém a água na pele
                                    para ajudar a manter um nível de hidratação
                                    ótimo.
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/617fb948ebbaeb31693807e5a660cfbb_f43.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                VITAMINA E
                                <div className="textBlock bottom">
                                  <p>
                                    A Vitamina E é um poderoso antioxidante
                                    natural que ajuda a proteger dos radicais
                                    livres que danificam as células da pele* e
                                    podem causar o envelhecimento prematuro da
                                    mesma.
                                  </p>
                                  <p className="footNote">*Testado in vitro</p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/d5cb67f17c9a6484836ce66200118918_f40.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                EXTRATO DE MENTA
                                <div className="textBlock bottom">
                                  <p>
                                    O Extrato de Menta é utilizado para diversos
                                    fins cosméticos. É conhecido, entre outras
                                    coisas, pelas suas fortes propriedades
                                    naturais refrescantes.{" "}
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/fdf3033b2f5f4b9f6c2f8a54acf02d47_f45.jpg" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="products-page__product clearfix">
                    <a name="lo-o-hidratante-intensificadora-do-bronzeado" />
                    <div className="products-page__product__image">
                      <img src="/assets/images/7fe593e9f045d355b8b43661d26dbfa3_f19-pt.jpg" />
                    </div>
                    <div className="products-page__product__details">
                      <h3>LOÇÃO HIDRATANTE INTENSIFICADORA DO BRONZEADO</h3>
                      <div className="products-page__product__details__benefits">
                        <h4>BENEFÍCIOS</h4>
                        <p>
                          A Loção Hidratante Intensificadora do Bronzeado de PIZ
                          BUIN<sup>®</sup> AFTER SUN contém Tanimel, um extrato
                          vegetal intensificador do bronzeado que ajuda a
                          aumentar a produção dos pigmentos bronzeadores
                          naturais da pele*. A fórmula de absorção rápida
                          proporciona 24h de hidratação para recuperar o
                          equilíbrio natural da pele sensibilizada pelo sol e
                          ajuda a prevenir a descamação proporcionando um
                          bronzeado mais duradouro.
                          <br />
                          <span className="footNote">*Teste in vitro</span>
                        </p>
                        <div className="products-page__product__details__benefits__hidden">
                          <p>
                            <span className="footNote">
                              <br />
                            </span>
                          </p>
                          <h2>disponível nos formatos</h2>
                          <p> 200 ml</p>
                        </div>
                        <button className="products-page__product__details__benefits__more">
                          Saiba Mais
                        </button>
                      </div>
                      <div className="products-page__product__details__ingredients">
                        <ul className="ingredientsList">
                          <li>
                            <a href="#" className="toggle">
                              INGREDIENTES E TECNOLOGIAS{" "}
                              <span className="toggle__plus">+</span>
                            </a>
                            <ul>
                              <li>
                                GLICERINA
                                <div className="textBlock bottom">
                                  <p>
                                    A Glicerina atrai e retém a água na pele
                                    para ajudar a manter um nível de hidratação
                                    ótimo.
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/617fb948ebbaeb31693807e5a660cfbb_f43.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                VITAMINA E
                                <div className="textBlock bottom">
                                  <p>
                                    A Vitamina E é um poderoso antioxidante
                                    natural que ajuda a proteger dos radicais
                                    livres que danificam as células da pele* e
                                    podem causar o envelhecimento prematuro da
                                    mesma.
                                  </p>
                                  <p className="footNote">*Testado in vitro</p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/d5cb67f17c9a6484836ce66200118918_f40.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                MANTEIGA DE KARITÉ
                                <div className="textBlock bottom">
                                  <p>
                                    A Manteiga de Karité deriva originalmente da
                                    árvore do karité, que cresce na África
                                    Ocidental. Foi utilizada durante séculos
                                    para hidratar e proteger a pele dos efeitos
                                    da exposição solar, vento, calor e água
                                    salgada. É igualmente muito usada em
                                    produtos cosméticos graças às suas
                                    propriedades nutritivas, hidratantes e
                                    protetoras.{" "}
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/4e8fd1f288f22f5c0578667ed26ebe09_f46.jpg" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                TANIMEL
                                <div className="textBlock bottom">
                                  <p>
                                    O Tanimel é um ingrediente ativo derivado da
                                    planta Ononis, que é comum na Europa.
                                  </p>
                                  <p>
                                    Ajuda a manter a produção de melanina
                                    natural do corpo durante ou fora da
                                    exposição solar, para que o seu bronzeado
                                    natural dure mais tempo *. Aumenta a
                                    produção de melanina até 133% *, permitindo
                                    um bronzeado mais profundo.
                                  </p>
                                  <p className="footNote">* Testado in vitro</p>
                                  <div> </div>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/6121365f8827c636e3a96d865c44ae54_f47.jpg" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="products-page__product clearfix">
                    <a name="after-sun-al-vio-imediato-bruma-express-" />
                    <div className="products-page__product__image">
                      <img src="/assets/images/2019-after-sun-instant-mist-spray-200ml.jpg" />
                    </div>
                    <div className="products-page__product__details">
                      <h3>AFTER SUN ALÍVIO IMEDIATO BRUMA EXPRESS </h3>
                      <div className="products-page__product__details__benefits">
                        <h4>BENEFÍCIOS</h4>
                        <p>
                          PIZ BUIN<sup>®</sup> After Sun Bruma Express Alívio
                          Imediato proporciona uma frescura hidratante para dar
                          conforto à sua pele. Com Ácido Hialurónico e
                          Glicerina, o After Sun Express proporciona 48h de
                          hidratação para ajudar a restaurar o equilíbrio
                          natural da sua pele e a prevenir a descamação, para um
                          bronzeado mais duradouro. <br />É fácil de aplicar e
                          tem uma textura ultraligeira que é absorvida
                          imediatamente. Fórmula não gordurosa e não pegajosa.
                        </p>
                      </div>
                      <div className="products-page__product__details__directions">
                        <h4>RECOMENDAÇÃO DE USO</h4>
                        <p>
                          Para 48h de hidratação, aplique depois do duche ou
                          banho após secar a pele com uma toalha.
                        </p>
                      </div>
                      <div className="products-page__product__details__ingredients">
                        <ul className="ingredientsList">
                          <li>
                            <a href="#" className="toggle">
                              INGREDIENTES E TECNOLOGIAS{" "}
                              <span className="toggle__plus">+</span>
                            </a>
                            <ul>
                              <li>
                                ALOÉ VERA
                                <div className="textBlock bottom">
                                  <p>
                                    O que é que torna o Aloé Vera único?
                                    <br />
                                    Aloé Vera é um hidratante natural rico, com
                                    poderosas propriedades suavizantes.
                                  </p>
                                  <div> </div>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/006e44dceb4947a77acdd3948503af8d_f37.jpg" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <nav className="platform-links">
              <div className="container">
                <ul>
                  <li>
                    <a
                      className="first"
                      href="/pt/nossos-produtos/hydro-infusion/"
                      target="_self"
                    >
                      Hydro Infusion
                    </a>
                  </li>
                  {/* <li>
                    <a
                      href="/pt/nossos-produtos/active-protect/"
                      target="_self"
                    >
                      Active &amp; Protect
                    </a>
                  </li> */}
                  <li>
                    <a href="/pt/nossos-produtos/moisturising/" target="_self">
                      Moisturising
                    </a>
                  </li>
                  <li>
                    <a href="/pt/nossos-produtos/tan_protect/" target="_self">
                      Tan &amp; Protect
                    </a>
                  </li>
                  {/* <li>
                    <a href="/pt/nossos-produtos/instantglow/" target="_self">
                      Instant Glow
                    </a>
                  </li> */}
                  <li>
                    <a href="/pt/nossos-produtos/allergy/" target="_self">
                      Allergy
                    </a>
                  </li>
                  <li>
                    <a
                      className="platform-links__active nav-path-selected"
                      href="/pt/nossos-produtos/aftersun/"
                      target="_self"
                    >
                      After sun
                    </a>
                  </li>
                  <li>
                    <a
                      href="/pt/nossos-produtos/todos_os_Produtos/"
                      target="_self"
                    >
                      Todos os Produtos
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
            <section id="social">
              <a
                href="https://www.facebook.com/PizBuinPortugal"
                target="_blank"
                className="fb"
              >
                &nbsp;
              </a>
              <a
                href="https://www.instagram.com/pizbuinportugal/"
                target="_blank"
                className="inst"
              >
                &nbsp;
              </a>
              <a
                href="https://www.youtube.com/channel/UCuRXMca5zj_v97YlGJip63A"
                target="_blank"
                className="yt"
              >
                &nbsp;
              </a>
            </section>
            <div className="clear">
              <br />
            </div>
            <Footer></Footer>
          </div>
        </div>
        <script src="/assets/js/main.js" />
        <script src="/assets/js/vendor/jquery.fancybox.js" />
        <script src="/assets/js/vendor/jquery.fancybox-media.js" />
        <script src="/assets/js/vendor/iealert.min.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function(){\n$('.products-page__product__details__benefits__more').on('click', function(){\nvar box = $(this).siblings('.products-page__product__details__benefits__hidden');\nif(box.is(':visible')){\nbox.slideUp();\n$(this).text('Saiba Mais');\n} else {\nbox.slideDown();\n$(this).text('Fechar');\n}\n});\n});\n"
          }}
        />
       <div className="clear">
          <br />
        </div>
      </div>
    );
  }
}

export default Page;
